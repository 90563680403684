import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";

const UsbDebug = () => {
  const title = "Android Device Update Issue",
    description = "Learn how to disable USB debugging and restart the WaFd Bank app.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/support/usb-debug"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      title: "Support",
      url: "/about-us/contact-us"
    },
    {
      id: 2,
      active: true,
      title: "USB Debug"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row align-items-center">
          <div className="col-md-4 col-lg-3">
            <StaticImage
              src="../../images/generic-android-smartphone.jpg"
              alt="Android smartphone"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-8 col-lg-9">
            <h1>Android Device Update Issue</h1>
            <p>
              A recent security update for Android device users has made it so the WaFd Bank mobile app will not open if
              a device has USB/Wireless Debugging enabled. This is a known issue, and we are working with Google to fix
              the issue.
            </p>
            <p>
              <a href="#usb-debug-faq-3">How to disable USB Debugging</a>
            </p>
          </div>
        </div>
      </section>
      <section className="container pt-0">
        <div id="usb-debug-faq-1">
          <h2>Why was this change made?</h2>
          <p>
            Having USB or Wireless Debugging enabled on your Android device makes it more vulnerable to cyber-attacks.
            To protect you and your financial information, our app will not launch until the USB/Wireless Debugging is
            disabled.
          </p>
        </div>
        <div id="usb-debug-faq-2">
          <h2>What is USB Debugging?</h2>
          <p>
            USB Debugging is a &ldquo;Developer Options&rdquo; tool, which allows USB connections between a device and
            computer. If you have a refurbished device, this feature was most likely used to wipe the previous content
            off the device before you purchased it.
          </p>
        </div>
        <div id="usb-debug-faq-3">
          <h2>How do I disable USB Debugging?</h2>
          <p>
            Users can disable Debugging via the Developer Options menu; however, where this is located on your device
            may vary depending on which make and model you have.
          </p>
        </div>
      </section>
      <section className="bg-success text-center">
        <h3 className="text-white">For best results, use Google to look up your specific device.</h3>
        <p className="text-white">For example, search &ldquo;Samsung Galaxy S8 turn off USB Debugging&rdquo;</p>
      </section>
      <section className="container">
        <h3 className="text-center">
          Below are the basic steps to disable USB Debugging on two common devices.
          <br />
          (Again, your device may vary)
        </h3>
        <div className="row">
          <div className="col-md-6 border-md-right">
            <h3 className="text-center">Device 1</h3>
            <ol>
              <li>Go to the Settings Menu (Icon should look like a round gear)</li>
              <li>Select Developer Options</li>
              <li>Scroll to find &ldquo;USB Debugging&rdquo; or &ldquo;Wireless Debugging&rdquo;</li>
              <li>Tap to disable</li>
            </ol>
          </div>
          <div className="col-md-6">
            <h3 className="text-center">Device 2</h3>
            <ol>
              <li>Go to the Settings Menu</li>
              <li>Select System</li>
              <li>Tap &ldquo;Advanced&rdquo;</li>
              <li>Tap &ldquo;Developer Options&rdquo;</li>
              <li>Scroll to find &ldquo;USB Debugging&rdquo; or &ldquo;Wireless Debugging&rdquo;</li>
              <li>Tap to disable</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <StaticImage
                src="../../images/woman-opening-wafd-app-smartphone.jpg"
                alt="Woman using WaFd Bank Mobile App in her smartphone"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6 col-lg-7">
              <h3>Launch WaFd Bank Mobile Banking</h3>
              <p>
                After you have disabled USB Debugging, you should be able to successfully launch the WaFd Bank mobile
                app.
              </p>
              <p>We thank you for your patience as we continue to work hard to protect your financial information.</p>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default UsbDebug;
